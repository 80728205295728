/* Existing CSS */
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

a:link {
  color: #2369F6;
  text-decoration: none;
}

a:visited {
  color: #2369F6;
  text-decoration: none;
}

.fullimage {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  padding: 10px;
}

.fullimage img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.halfcontent {
  display: flex;
  height: 100vh;
  padding-left: 10px; 
  padding-right: 10px;
}

.halfcontent .text {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
}

.halfcontent .image {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.halfcontent .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

nav ul li {
  margin-bottom: 8px;
}